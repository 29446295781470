import React from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import App from './App';
import './App.css';
import './utilities.css';
import './i18n/config';
import './index.css';
import './assets/css/tailwind.output.css';
// import 'swiper/css';
// import 'swiper/css/pagination';
import {SidebarProvider} from 'context/SidebarContext';
import DrawerContextProvider from 'context/DrawerContext';
import NewCourseContextProvider from 'context/NewCourseSteperContext';
import BookRetakeContextProvider from 'context/BookRetakeContext';
import BookingManagementContextProvider from 'context/BookingManagementContext';
import store from "../src/reduxStore/store";
import { Provider } from 'react-redux';


function Root() {

  console.log = console.warn = console.error = console.info = () => {};


  // use the useTranslation hook inside a function component
  const { i18n } = useTranslation();
  const dir =  i18n.language === 'en' ? 'ltr' : 'rtl'
  // // console.log('index dir: ',dir)
  return (
    <div dir={dir}>
      <SidebarProvider>
      {/* <Provider store={store}> */}
        <DrawerContextProvider>
          <NewCourseContextProvider>
            <BookRetakeContextProvider>
              <BookingManagementContextProvider>
                <App />
              </BookingManagementContextProvider>
            </BookRetakeContextProvider>
          </NewCourseContextProvider>
        </DrawerContextProvider>
        {/* </Provider> */}
      </SidebarProvider>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Root /> 
  // </React.StrictMode>
);
