import config from '../config';
import axios from 'axios';

function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return { Authorization: 'Bearer ' + user };
    } else {
      return {};
    }
}
export default async function apiRequest(type,path,data = {}) {
    try{
        if(!type || !path) {
            throw new Error('type/path undefined')
        }
        const headers = authHeader();

        let request = await axios({
            method: type,
            url: config.API_URL + path,
            data: data,
            headers: headers
          });
        return request;
    } catch(error){
        console.log('apiRequest error: ',type,config.API_URL + path,error)
        throw new Error('failed to apiRequest')
    }
  }
