import { toast } from "react-hot-toast";


const AlertMessage = (message = '', type = 'success', duration = 5000, messageType = 'toast') => {
  if(type === 'success'){
    toast.success(message, {
        duration: duration,
    });
  } else {
    toast.error(message, {
        duration: duration,
    });
  }
};

const AlertServices = {
    AlertMessage,
};

export default AlertServices;


